import './App.css';
import 'antd/dist/antd.css';
import React from 'react';
import {dataQuestions, formules} from './data/data-question';
import {Radio, Divider, Row, message, Modal, Card, Col, Layout, Button, Typography} from 'antd';
import vistalidLogo from './images/logovistalid-degrade.png'
import ecommerceLogo from './images/illustration-ecommerce.png'
import Pdf from "react-to-pdf";

const {Text} = Typography;

const {Meta} = Card;
const {Content, Footer} = Layout;

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            formule: {}
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event, key) {
        this.setState({
            [key]: event.target.value
        })
    }

    handleSubmit(event) {
        if (Object.keys(this.state).length - 2 === dataQuestions.length) {
            this.setState({modal: true})
            this.countTotal(this.state, this.renderFormule)
        } else {
            message.error('Veuillez répondre à toutes les questions');
        }
        event.preventDefault();
    }

    countTotal = (state, renderFormule) => {
        let count = 0;
        const array = ['modal', 'formule'];
        for (const item in state) {
            if (array.indexOf(item) === -1 && typeof (state[item]) === 'number') {
                count = count + state[item]
            }
        }
        this.renderFormule(count, formules)
    }

    handleCancel = () => {
        this.setState({modal: false})
    };

    renderFormule = (score, formules) => {
        let higherNumber = 0;
        for (let i = 0; i <= 11 ; i++) {
            higherNumber = this.state[i] >= higherNumber ? this.state[i] : higherNumber;
        }
        formules.map(item => {
            if (higherNumber === item.pack) {
                this.setState({formule: item})
            }
        })
    }

    render() {
        const ref = React.createRef();
        const options = {
            orientation: "portrait",
            unit: "px",
            format: [1654, 2339]
        };

        return (
            <div className="App" ref={ref}>
                <Layout className="layout">
                    <Content style={{padding: '0 50px'}}>
                        <div className="site-layout-content">
                            <div>
                                <Modal open={this.state.modal} onCancel={this.handleCancel}
                                       footer={[
                                           <Button onClick={this.handleCancel}>Retour</Button>,
                                           <Pdf targetRef={ref} filename="code-example.pdf"
                                                scale={1.6}
                                                options={options}>
                                               {({toPdf}) => <button onClick={toPdf}>Générer le Pdf</button>}
                                           </Pdf>
                                       ]}
                                       width='80vw'>
                                    <div className="card-content">
                                        <div className="site-layout-content">
                                            <div className="brand-content">
                                                <Row style={{justifyContent: 'center'}}>
                                                    <img src={vistalidLogo} alt="Logo-vistalid"
                                                         title="Logo-vistalid"/>
                                                </Row>
                                            </div>
                                            <Row style={{justifyContent: 'center'}}>
                                                {
                                                    formules.map((item, index) => {
                                                        return (
                                                            <Card
                                                                className={item.label === this.state.formule.label ? 'item-card active' : 'item-card'}
                                                                key={index}
                                                                hoverable
                                                                style={{width: 300}}
                                                                cover={<img alt="example"
                                                                            src={ecommerceLogo} alt="Logo-vistalid"
                                                                            title={item.title}/>}
                                                            >
                                                                <Meta
                                                                    title={item.title} description={item.label}/>
                                                            </Card>
                                                        )
                                                    })
                                                }
                                            </Row>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                            <Row>
                                <Col xs={{span: 24, offset: 0}} lg={{span: 18, offset: 4}} xxl={{span: 12, offset: 6}}>
                                    <div className="brand-content">
                                        <Row>
                                            <Col xs={24} lg={12}>
                                                <img src={vistalidLogo} alt="Logo-vistalid" title="Logo-vistalid"/>
                                            </Col>
                                            <Col style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }} xs={24} lg={12}>
                                                <h2>Service Ecommerce</h2>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="header">
                                        <Row>
                                            <Col xs={24} lg={12}>
                                                <img src={ecommerceLogo} alt="Logo-vistalid" title="Logo-vistalid"/>
                                            </Col>
                                            <Col xs={24} lg={12}>
                                                <h1>Formulaire commercial</h1>
                                                <p>Nous définissons vos besoins et retenons la formule adéquate pour
                                                    votre boutique
                                                    e-commerce. Nous réalisons alors une étude approfondie afin de vous
                                                    proposer un
                                                    site e-commerce sur-mesure, adapté à votre entreprise et vos
                                                    objectifs.</p>
                                            </Col>
                                        </Row>
                                    </div>
                                    <form onSubmit={this.handleSubmit}>
                                        {
                                            dataQuestions.map((item, index) => {
                                                return (
                                                    item.option.length >= 1 ?
                                                        <Row className="item-content"
                                                             style={{backgroundColor: +item.backgroundColor != null ? item.backgroundColor : ''}}
                                                        >
                                                            <Divider orientation="center"
                                                                     plain>{item.label}</Divider>
                                                            <div className="item-description">
                                                                {item.description ?
                                                                    <Text disabled>{item.description}</Text> : ''}
                                                            </div>
                                                            <Radio.Group index={item.key}
                                                                         onChange={(event) => this.handleChange(event, item.key)}
                                                                         required>
                                                                <div className="label-content">
                                                                    {item.option.map(input => {
                                                                        return (
                                                                            <Radio.Button index={input.key}
                                                                                          value={input.value}>{input.name}</Radio.Button>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </Radio.Group>
                                                        </Row>
                                                        : ''
                                                )
                                            })
                                        }
                                        <div className="btn-content">
                                            <input className="btn-submit" type="submit" value="Envoyer"/>
                                        </div>
                                    </form>
                                </Col>
                            </Row>
                        </div>
                        <div style={{display: this.state.modal ? 'block' : 'none'}} className="card-content">
                            <Row style={{justifyContent: 'center'}}>
                                {
                                    formules.map(item => {
                                        return (
                                            <Card
                                                className={item.label === this.state.formule.label ? 'item-card active' : 'item-card'}
                                                hoverable
                                                style={{width: 300}}
                                                cover={<img alt="example"
                                                            src={ecommerceLogo} alt="Logo-vistalid"
                                                            title={item.title}/>}
                                            >
                                                <Meta
                                                    title={item.title} description={item.label}/>
                                            </Card>
                                        )
                                    })
                                }
                            </Row>
                        </div>
                    </Content>
                    <Footer style={{textAlign: 'center'}}>Developed with ❤️ by <a
                        href="https://vistalid.fr">Vistalid</a></Footer>
                </Layout>
            </div>
        );
    }
}

export default App;
