export const dataQuestions = [
    {
        label: '1. Nombre de page vitrine sur mon site  :',
        key: 1,
        type: 'radio',
        backgroundColor: null,
        option: [
            {
                name: 'Jusqu\'à 4 pages vitrine',
                value: 1,
                key: 1
            },
            {
                name: 'Jusqu\'à 7 pages vitrine',
                value: 2,
                key: 2
            },
            {
                name: 'Jusqu\'à 11 pages vitrine',
                value: 3,
                key: 3
            },
            {
                name: 'Plus de 11 pages vitrine ',
                value: 4,
                key: 4
            },
        ]
    },
    {
        label: '2. Les catégories de mon site:  (7 catégories affichées maximum dans le menu) :',
        key: 2,
        type: 'radio',
        backgroundColor: '#fff',
        option: [
            {
                name: 'Jusqu\'à 4 catégories',
                value: 1,
                key: 1
            },
            {
                name: 'Jusqu\'à 6 catégories',
                value: 2,
                key: 2
            },
            {
                name: 'Jusqu\'à 7 catégories',
                value: 3,
                key: 3
            },
            {
                name: 'Jusqu\'à 10 catégories',
                value: 4,
                key: 4
            },
        ]
    },
    {
        label: '3. Les sous-catégories de mon site:',
        description: 'Les sous-catégories ont pour parent les catégories. Exemple: catégories "Vétement", sous-catégorie "Veste, T-shirt, Pantalon".',
        key: 3,
        type: 'radio',
        backgroundColor: null,
        option: [
            {
                name: 'Jusqu\'à 10 sous-catégories en tout',
                value: 1,
                key: 1
            },
            {
                name: 'Jusqu\'à 20 sous-catégories en tout',
                value: 2,
                key: 2
            },
            {
                name: 'Jusqu\'à 30 sous-catégories en tout',
                value: 3,
                key: 3
            },
            {
                name: 'Plus de 30 sous-catégories en tout',
                value: 4,
                key: 4
            },
        ]
    },
    {
        label: '4. Nombre de produits :',
        key: 4,
        type: 'radio',
        backgroundColor: '#fff',
        option: [
            {
                name: 'Jusqu\'à 20 produits',
                value: 1,
                key: 1
            },
            {
                name: 'Jusqu\'à 40 produits',
                value: 2,
                key: 2
            },
            {
                name: 'Jusqu\'à 60 produits',
                value: 3,
                key: 3
            },
            {
                name: 'Plus de 60 produits',
                value: 4,
                key: 4
            },
        ]
    },
    {
        label: '5. Nombre de déclinaisons :',
        description: 'Exemple de déclinaison pour un produit: Taille: XS, S, M, ...',
        key: 5,
        type: 'radio',
        backgroundColor: null,
        option: [
            {
                name: 'Jusqu\'à 10 déclinaisons',
                value: 1,
                key: 1
            },
            {
                name: 'Jusqu\'à 20 déclinaisons',
                value: 2,
                key: 2
            },
            {
                name: 'Jusqu\'à 30 déclinaisons',
                value: 3,
                key: 3
            },
            {
                name: 'Plus de 30 déclinaisons',
                value: 4,
                key: 4
            },
        ]
    },
    {
        label: '6. Site Ecommerce déjà existant :',
        key: 6,
        type: 'radio',
        backgroundColor: '#fff',
        option: [
            {
                name: 'Oui',
                value: 1,
                key: 1
            },
            {
                name: 'Non',
                value: 2,
                key: 2
            },
        ]
    },
    {
        label: '7. Export des produits/catégories/sous-catégories possible :',
        key: 7,
        type: 'radio',
        backgroundColor: null,
        option: [
            {
                name: 'Oui',
                value: 1,
                key: 1
            },
            {
                name: 'Non',
                value: 2,
                key: 2
            },
        ]
    },
    {
        label: '8 Les paiements :',
        key: 8,
        type: 'radio',
        backgroundColor: '#fff',
        option: [
            {
                name: 'Crédit agricole et/ou PayPal pro',
                value: 1,
                key: 1
            },
            {
                name: 'Autre module',
                value: 2,
                key: 2
            },
        ]
    },
    {
        label: '9. Les transporteurs :',
        key: 9,
        type: 'radio',
        backgroundColor: null,
        option: [
            {
                name: 'Click & collect',
                value: 1,
                key: 1
            },
            {
                name: 'Mondial relay',
                value: 1,
                key: 2
            },
            {
                name: 'Colissimo',
                value: 1,
                key: 3
            },
            {
                name: 'Drive',
                value: 2,
                key: 4
            },
            {
                name: 'Autre',
                value: 3,
                key: 5
            },
        ]
    },
    {
        label: '10. Création de boite e-mail :',
        key: 10,
        type: 'radio',
        backgroundColor: '#fff',
        option: [
            {
                name: 'Pas de création d\'email',
                value: 1,
                key: 1
            },
            {
                name: 'Jusqu\'à 5 e-mails',
                value: 2,
                key: 2
            },
            {
                name: 'Jusqu\'à 10 e-mails',
                value: 3,
                key: 3
            },
            {
                name: 'Plus de 10 e-mails',
                value: 4,
                key: 4
            },
        ]
    },
    {
        label: '11. Transfert d\'e-mail :',
        key: 11,
        type: 'radio',
        backgroundColor: '#fff',
        option: [
            {
                name: 'Pas de Transfert d\'email',
                value: 1,
                key: 1
            },
            {
                name: 'Transfert jusqu\'à 5 e-mails',
                value: 3,
                key: 2
            },
            {
                name: 'Jusqu\'à 10 e-mails',
                value: 4,
                key: 3
            },
        ]
    },
];

export const formules = [
    {
        title: 'Pack Lite',
        label: 'Classique',
        key:1,
        pack: 1,
    },
    {
        title: 'Pack Basic',
        label: 'Avancé',
        key:2,
        pack: 2,
    },
    {
        title: 'Pack Plus',
        label: 'Expert',
        key:3,
        pack: 3,
    },
    {
        title: 'Pack Ultra',
        label: 'Sur mesure',
        key:4,
        pack: 4,
    },
]